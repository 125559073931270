<template>
  <div class="Program">
    <div v-for="(item, index) in schemeList" :key="index" class="Program_item">
      <el-card>
        <div class="program">
          <div class="program_top">
            <el-popconfirm
              title="这是一段内容确定删除吗？"
              @onConfirm="delProgram(item.id)"
            >
              <i class="el-icon-error" slot="reference"></i>
            </el-popconfirm>
          </div>
          <div class="program_main">
            <img :src="item.cover" />
          </div>
          <div class="program_title">
            {{ item.title }}
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      schemeList: [],
    };
  },
  created() {
    this.getSchemeList();
  },
  methods: {
    // 获取方案数据
    getSchemeList() {
      this.$axios({
        url: this.$api + "/auth/ppt/indexlist",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          page: 1,
          title: "",
          username: "",
          cat_id: "",
          hot: "",
          new: "",
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.schemeList = res.data.data.res;
        }
      });
    },
    // 删除方案
    delProgram(id) {
      this.$axios({
        url: this.$api + "/admin/ppt/handleppt",
        method: "DELETE",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          cat: 1,
          item_id: id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
          this.getSchemeList();
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.Program {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.Program_item {
  width: 23%;
  margin: 1%;
}
.program_top {
  margin: 0 0 10px;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.program_main {
  width: 100%;
  height: 180px;
}
.program_main > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.program_title {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (min-width: 2049px) and (max-width: 4096px) {
  .program_main{
    height: 320px;
  }
  .program_title{
    font-size: 24px;
  }
  .program_top{
    font-size: 24px;
  }
}
</style>